import { VehicleImageUrls } from "static/js/app/models/vehicleImageUrls";
export function buildVehicleImageThumbnails (images:VehicleImageUrls[], numberOfExtraImages:number, enableCustomVehicleImageRatio:boolean = false): string {
  if(numberOfExtraImages == null){
    numberOfExtraImages = 3;
  }
  let numberOfExtraImagesCalc = 3 + 1;
    if (images == null || images.length == 0 || numberOfExtraImages == null || numberOfExtraImages == 0 || images.length < numberOfExtraImagesCalc ) {
      return "";
    }
    else {
      
      if (enableCustomVehicleImageRatio) {
        let thumbImages = images.slice(1,4)
                                .map(im=> `<li>
                                <picture>
                                  <source type="image/webp" srcset="${im.i320x240}.webp">
                                  <source type="image/jpeg" srcset="${im.i320x240}">
                                  <img src="${im.i320x240}" class="">
                                </picture>
                                </li>`)
                                .join("");
        let re = /i320x240/gi;
        let newstr = thumbImages.replace(re, "w320");
        let thumbs = `<ul class="vehicle-results-thumbnails">${newstr}</ul>`;
        return thumbs;

      }
      else {
        let thumbImages = images.slice(1,4)
          .map(im=> `<li>
          <picture>
            <source type="image/webp" srcset="${im.i320x240}.webp">
            <source type="image/jpeg" srcset="${im.i320x240}">
            <img src="${im.i320x240}" class="">
          </picture>
          </li>`)
          .join("");
        let thumbs = `<ul class="vehicle-results-thumbnails">${thumbImages}</ul>`;
        return thumbs;
      }

    }
}

export function buildVehicleImageThumbnailsSlick (images:VehicleImageUrls[], numberOfExtraImages:number): string {
  numberOfExtraImages = 3;
    if (images == null || images.length <= 1 || numberOfExtraImages == null || numberOfExtraImages <= 1) {
        return "";
    }
    else {
      
      let thumbImages = images.slice(0,3)
                              .map(im=> `<li>
                              <picture>
                                <source type="image/webp" srcset="${im.i320x240}.webp">
                                <source type="image/jpeg" srcset="${im.i320x240}">
                                <img src="${im.i320x240}" data-placeholder="/basemedia/placeholder.jpg">
                              </picture>
                              </li>`)
                              .join("");
      let thumbs = `<ul class="slick slick--vehicle-results-thumbnails vehicle-results-thumbnails">${thumbImages}</ul>`;
      return thumbs;
    }
}
